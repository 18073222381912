.grid-el {
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;

    @include breakpoint-min('small-screen') {
        width: 45%;
        justify-content: flex-start;
        align-items: flex-start;
    }

    @include breakpoint-min('desktop') {
        width: 42%;
    }

    &__header {
        width: 100%;
        
        @include breakpoint-min('large-tablet') {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: flex-end;
        }
    }

    &__header-image {
        display: block;
        margin: 0 auto 30px;

        @include breakpoint-min('large-tablet') {
            margin: 0 0 30px;
        }
    }

    &__text {

    }

    &__button {
        @include breakpoint-min('small-screen') {

        }
    }
}