.section-title {
    &__wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 30px;
        margin-top: 32px;
    }

    &__title {
        color: var($--theme-text);
        text-transform: uppercase;
        text-align: left;
        font-size: 1.5rem; // 24px
        line-height: 2rem; // 32px
        font-weight: $lightWeight;

        @include breakpoint-min('desktop') {
            // font-size: 2rem;
        }
        
        &--primary {
            color: var($--theme-primary);
            text-transform: uppercase;
            text-align: left;
            font-size: 0.75rem; // 12px
            line-height: 1.2rem; // 20px
            font-weight: $regularWeight;
        }
    }

    &__decoration {
        position: absolute;
        top: -50px;
        left: -20px;
        max-height: 250%;
        // height: 20%;
        width: auto;
        pointer-events: none;

        // @include breakpoint-min('large-tablet') {
        //     max-height: 50%;
        // }
    }
}