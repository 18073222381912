.section-title {
    font-weight: $superBoldWeight;
    font-size: 1.6rem;
    line-height: 2rem;
    padding-bottom: 15px;
    margin-bottom: 30px;
    font-style: normal;
    position: relative;

    @include breakpoint-min('large-tablet') {
        font-size: 1.7rem;
        line-height: 2.1rem;
    }

    @include breakpoint-min('small-screen') {
        font-size: 1.9rem;
        line-height: 2.3rem;
    }
    
    @include breakpoint-min('desktop') {
        font-size: 2rem;
        line-height: 2.4rem;
    }

    &__decorator {
        display: block;
        height: 5px;
        width: 30px;
        position: absolute;
        bottom: 0;
        left: -30px;

        @include breakpoint-min('tablet') {
            width: 45px;
            left: -45px;
        }

        @include breakpoint-min('large-tablet') {
            width: 60px;
            left: -60px;
        }
    
        &--primary {
            background: var($--theme-primary);
        }

        &--secondary {
            background: var($--theme-secondary);
        }
    }
    
    &--no-margin {
        margin-bottom: 0;
    }
}