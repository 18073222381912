.block {
    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        position: relative;

        @include breakpoint-min('large-tablet') {
            align-items: flex-start;
        }

        @include breakpoint-min('small-screen') {
            flex-direction: row;
            flex-wrap: wrap;
        }

        &-shadow {
            background: rgb(255,255,255);
            background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
            display: block;
            width: 100%;
            height: 150px;
            position: absolute;
            bottom: 0;
            left: 0;

            @include breakpoint-min('large-tablet') {
                display: none;
            }
        }
    }

    background: var($--theme-primary-pale);
    padding: 15px 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 5px;
    box-sizing: border-box;

    &--hide {
        display: none;

        @include breakpoint-min('large-tablet') {
            display: flex;
        }
    }

    @include breakpoint-min('large-tablet') {
        width: fit-content;
    }

    @include breakpoint-min('small-screen') {
        margin-right: 5px;
    }

    &__icon {
        margin-right: 10px;
    }

    &__text {
        text-transform: uppercase;
        font-weight: $semiBoldWeight;
        font-size: 1rem;
    }
}