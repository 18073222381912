.ah-group {
    &-localisations {
        &-section {
            background: var($--theme-banner-background);
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            width: 100%;

            &__image {
                display: none;
                max-width: 100%;
                margin-top: 80px;

                @include breakpoint-min('large-tablet') {
                    display: block;
                }

                &--mobile {
                    max-width: 100%;

                    @include breakpoint-min('large-tablet') {
                        display: none;
                    }
                }
            }

            &__content-wrapper {
                &--mobile {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;

                    @include breakpoint-min('large-tablet') {
                        display: none;
                    }
                }
            }
        }

        &-details {
            &__wrapper {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: flex-start;
                width: 100%;
                margin-bottom: 16px;
                cursor: pointer;
            }
            
            &__title {
                font-weight: $boldWeight;
                text-transform: uppercase;
                font-size: 0.75rem; // 12px
                line-height: 1.2rem; // 20px
                letter-spacing: 5%;

                &-wrapper {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    align-items: center;
                }
            }
            
            &__chevron-icon {
                transition: all $transitionDuration;
                transform: rotate(180deg);
                
                &--rotate {
                    transform: rotate(0deg);
                }
            }

            &__text {
                letter-spacing: 5%;
                font-size: 0.75rem; // 12px
                line-height: 1.2rem; // 20px
                text-align: left;
                white-space: pre-line;
                text-transform: uppercase;
                display: none;

                &--show {
                    display: block;
                }
            }
        }
    }
}