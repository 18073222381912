.home-tile {
    &__wrapper {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        width: 100%;
        text-decoration: none;
        position: relative;
        transition: all $transitionDuration;
        padding-bottom: 195px;
        margin-bottom: 50px;

        &:hover {
            // .home-tile__picture--desktop {
            //     transform: scaleY(0.95) translateY(-4%);
            // }

            .home-tile__text-wrapper {
                height: 250px;
            }

            .home-tile__button-link-wrapper {
                opacity: 1;
            }
        }

        @include breakpoint-min('large-tablet') {
            cursor: default !important;
        }
    }

    &__text-wrapper {
        padding: 16px 16px 32px 16px;
        width: 100%;
        box-sizing: border-box;
        transition: all $transitionDuration;
        background: var($--theme-background);

        @include breakpoint-min('desktop') {
            padding: 60px 60px 32px;

            position: absolute;
            bottom: 0;
            z-index: 20;
            height: 195px;
        }
    }

    &__picture {
        max-width: 100%;
        width: 100%;
        height: auto;

        @include breakpoint-min('large-tablet') {
            display: none;
        }

        &--desktop {
            display: none;
            max-width: 100%;
            height: auto;
            transition: all $transitionDuration;

            @include breakpoint-min('large-tablet') {
                display: block;
                min-height: 290px;
            }
        }
    }

    &__title, &__caption {
        text-transform: uppercase;
        text-decoration: none;
        color: var($--theme-primary);
        text-align: left;
        font-size: 1.26rem;
        line-height: 1.7rem;
        font-weight: $lightWeight;
    }

    &__caption {
        font-size: 0.75rem;
        line-height: 1.2rem;
        color: var($--theme-text);
    }

    &__button-link-wrapper {
        margin-top: 45px;
        // margin-bottom: 120px;
        opacity: 0;
        display: none;
        transition: all $transitionDuration;

        @include breakpoint-min('large-tablet') {
            display: flex;
        }
    }

    &__decoration {
        position: absolute;
        bottom: 0;
        left: 8%;
        max-height: 100%;
        height: 100%;
        width: auto;
        pointer-events: none;
        z-index: 30;

        @include breakpoint-min('large-tablet') {
            max-height: 50%;
        }
    }
}