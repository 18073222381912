.banner {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    position: relative;
    // background: var($--theme-banner-background);
    background: rgb(244,239,227);
    background: linear-gradient(0deg, rgba(244,239,227,1) 60%, rgba(226,211,174,1) 100%);
    color: var($--theme-text);

    &__background {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &__content-wrapper {
        padding-top: 48px;
        padding-bottom: 48px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        width: 100%;
        height: auto;
        box-sizing: border-box;

        @include breakpoint-min('small-screen') {
            padding-top: 80px;
            padding-bottom: 64px;
        }

        @include breakpoint-min('desktop') {
            padding-top: 100px;
            padding-bottom: 84px;
            width: 75%;
        }
    }

    &__header, &__paragraph {
        &-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin-bottom: 30px;
        }

        &-title {
            color: var($--theme-text);
            text-transform: uppercase;
            text-align: left;
            font-size: 1.75rem;
            line-height: 2.5rem;
            font-weight: $regularWeight;

            @include breakpoint-min('desktop') {
                font-size: 2rem;
            }
            
            &--primary {
                color: var($--theme-primary);
                text-transform: uppercase;
                text-align: left;
                font-size: 0.75rem;
                line-height: 1.2rem;
                font-weight: $regularWeight;
            }
        }

        &-text {
            font-weight: $regularWeight;
            font-size: 0.75rem;
            line-height: 1.2rem;
            text-align: left;
            
            @include breakpoint-min('desktop') {
                // max-width: 50%;
            }
        }
    }

    &__paragraphs-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        @include breakpoint-min('desktop') {
            flex-direction: row;
        }
    }

    &__paragraph {
        &-wrapper {
            margin-bottom: 16px;

            @include breakpoint-min('desktop') {
                margin-right: 100px;
            }
        }
    }

    &__decoration {
        position: absolute;
        top: 0;
        left: 0;
        max-height: 50%;
        height: 50%;
        width: auto;
        pointer-events: none;

        // @include breakpoint-min('large-tablet') {
        //     max-height: 50%;
        // }
    }
}