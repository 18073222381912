@mixin breakpoint-min($breakpoint) {
    // If the key exists in the map
    @if map-has-key($breakpoints, $breakpoint) {
        // Prints a media query based on the value
        @media #{inspect(map-get($breakpoints, $breakpoint))} {
            @content;
        }
    } 
    @else {
        @error "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        +"Available breakpoints are: #{map-keys($breakpoints)}.";
    }
}

// USAGE:

// @include breakpoint-min('tablet') {
//     color: blue;
// }

// ---------------------

// Custom devices
@mixin rwdCustom($screen) {
    @media (min-width: $screen+'px') {
        @content;
    }
}

// USAGE:

// @include rwdCustom(666) {
//     color: red;
// }

// ---------------------

@mixin spread-map($map: ()) {
    @each $key, $value in $map {
        #{$key}: $value;
    }
}