.App {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: var($--theme-background);
    text-align: center;
}

.home {
    &__tiles-wrapper {
        width: 100%;
        max-width: 1920px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        @include breakpoint-min('large-tablet') {
            flex-direction: row;
            justify-content: center;
        }
    }
}

// @media (prefers-reduced-motion: no-preference) {
//     .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//     }
// }

// .App-header {  
//     width: 45%;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     font-size: calc(10px + 2vmin);
//     color: white;
// }

// .App-link {
//     color: #61dafb;

//     &--active {
//         color: red;
//     }
// }

// @keyframes App-logo-spin {
//     from {
//     transform: rotate(0deg);
//     }
//     to {
//     transform: rotate(360deg);
//     }
// }

// .App-line {
//     height: 50%;
//     width: 2px;
//     background-color: white;
// }

// .App-main {  
//     height: 100%;
//     width: 45%;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     font-size: calc(10px + 2vmin);
//     color: white;
// }

// ------------

.grid-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    max-width: $maxWidth;
    margin: 0 auto;
    padding: 30px;

    @include breakpoint-min('tablet') {
        padding: 30px 45px;
    }

    @include breakpoint-min('large-tablet') {
        padding: 30px 60px;
    }

    &__half {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: flex-start;

        @include breakpoint-min('small-screen') {
            flex-direction: row;
            justify-content: space-between;
            align-items: stretch;
        }
    }
}