.kontakt {
    &__sections-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        @include breakpoint-min('small-screen') {
            flex-direction: row;
            align-items: flex-start;
            justify-content: center;
        }
    }

    &__banner {
        display: none;
        max-width: 40%;
        width: 40%;
        height: auto;
        max-height: 85vh;

        @include breakpoint-min('small-screen') {
            display: block;
        }

        @include breakpoint-min('desktop') {
            max-width: 50%;
            width: 50%;
        }

        &--mobile {
            display: block;
            width: 100%;
            height: auto;
            
            @include breakpoint-min('small-screen') {
                display: none;
            }
        }
    }

    &__content-wrapper {
        width: 100%;
        box-sizing: border-box;

        @include breakpoint-min('small-screen') {
            height: 100%;
            width: 60%;
            padding: 10px 30px;
        }

        @include breakpoint-min('desktop') {
            padding: 10px 50px;
            width: 50%;
        }
    }

    &__section-title {
        .section-title__title {
            font-size: 1.75rem; // 28px
            line-height: 40px;
            font-weight: $lightWeight;
    
            @include breakpoint-min('desktop') {
                font-size: 48px;
                line-height: 60px;
            }
        }
    }

    &__contact-info {
        &-text {
            font-weight: $lightWeight;
            font-size: 1rem; // 16px
            line-height: 1.75rem; // 28px
            text-align: left;
            margin-bottom: 30px;

            @include breakpoint-min('desktop') {
                font-size: 1.2rem; // 20px
                line-height: 2rem; // 32px
            }

            &--mail {
                color: var($--theme-primary);
            }
        }

        &-group {
            margin-bottom: 30px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }

        &-subtitle {
            font-weight: $regularWeight;
            text-transform: uppercase;
            text-align: left;
            font-size: 0.75rem; // 12px
            line-height: 1.2rem; // 20px
        }

        &-title {
            font-weight: $lightWeight;
            text-transform: uppercase;
            text-align: left;
            font-size: 1.5rem; // 24px
            line-height: 2rem; // 32px
        }

        &-email {
            font-weight: $lightWeight;
            text-align: left;
            font-size: 1rem; // 16px
            line-height: 1.75rem; // 28px
            color: var($--theme-primary);
        }

        &-phone {
            font-weight: $lightWeight;
            text-align: left;
            font-size: 1rem; // 16px
            line-height: 1.75rem; // 28px
        }
    }
}