.wartosci {
    &__banner {
        background: rgb(255,255,255);
        background: linear-gradient(0deg, rgba(255,255,255,1) 60%, rgba(226,211,174,1) 100%);
    }

    &__section-wrapper {
        background: var($--theme-banner-background);

        @include rwdCustom(960) {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: flex-start;
        }

        &--reverse {
            flex-direction: row-reverse;
        }
    }

    &__image {
        display: block;
        max-width: 100%;
        width: auto;
        height: auto;
        
        @include rwdCustom(960) {
            max-width: 50%;
        }
    }

    &__section {
        &-text {
            font-weight: $lightWeight;
            font-size: 1rem; // 16px
            line-height: 1.75rem; // 28px
            color: var($--theme-text);
            text-align: left;
            width: 100%;
            
            &-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;
                padding: 32px 16px;
                box-sizing: border-box;

                @include rwdCustom(960) {
                    padding: 30px;
                }

                @include rwdCustom(1100) {
                    padding: 50px;
                }

                @include breakpoint-min('desktop') {
                    padding: 100px;
                    font-size: 1.2rem; // 20px
                    line-height: 2rem; // 32px
                }

                @include rwdCustom(1600) {
                    padding: 150px;
                }

                @include rwdCustom(1800) {
                    padding: 175px;
                }

                @include rwdCustom(1900) {
                    padding: 200px;
                }
            }

            &-row {
                box-sizing: border-box;
                width: 100%;
                min-width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: center;
                align-items: center;

                &:first-child {
                    margin-bottom: 30px;
                }
            }

            &-number {
                font-size: 96px;
                line-height: 96px;
                text-align: center;
                font-weight: $lightWeight;
                color: var($--theme-primary-pale);
                margin-right: 16px;
                min-width: 80px;
                padding: 12px;
                box-sizing: border-box;

                @include breakpoint-min('desktop') {
                    margin-right: 60px;
                }
            }
        }
    }

    &__text {
        font-weight: $lightWeight;
        font-size: 1rem; // 16px
        line-height: 1.75rem; // 28px
        color: var($--theme-text);
        text-align: center;
        padding-top: 32px;
        padding-bottom: 32px;

        @include breakpoint-min('large-tablet') {
            padding: 50px 100px;
        }
        
        @include breakpoint-min('small-screen') {
            padding: 60px 130px;
        }

        @include breakpoint-min('desktop') {
            padding: 120px 260px;
        }
        
        &--bold {
            font-weight: $boldWeight;
        }
    }
}