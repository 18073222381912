.page-header {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    max-width: $maxWidth;
    margin: 0 auto;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 60px 30px;

    @include breakpoint-min('tablet') {
        padding: 60px 45px;
    }

    @include breakpoint-min('large-tablet') {
        padding: 60px;
    }

    @include breakpoint-min('small-screen') {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 80px 60px;
    }

    @include breakpoint-min('desktop') {
        padding: 80px 0;
    }

    &__half {
        width: 100%;

        @include breakpoint-min('small-screen') {
            width: 40%;
        }

        @include breakpoint-min('desktop') {
            width: 35%;
        }

        &--hide {
            display: none;
        }

        &--full {
            width: 100%;
        }

        &--second {
            @include breakpoint-min('small-screen') {
                width: 50%;
                margin-left: 0;
            }

            @include breakpoint-min('desktop') {
                width: 60%;
            }
        }
    }

    &__subtitle {
        text-transform: uppercase;
        text-align: left;
        margin-bottom: 10px;

        @include breakpoint-min('tablet') {
            margin-bottom: 20px;
        }

        @include breakpoint-min('large-tablet') {
            margin-bottom: 30px;
        }

        &--hide {
            display: none;
        }
    }

    &__text {
        text-align: left;
        
        @include breakpoint-min('tablet') {
            font-size: 1.1rem;
        }
    }

    &__button {

    }

    &__image {
        display: block;
        max-width: 100%;
        margin: 60px auto 0;

        @include breakpoint-min('small-screen') {
            margin: 0 auto;
        }

        &--full {
            width: 100%;
        }

        &--mobile {
            display: block;
            margin: 0 auto;
            max-width: 363px;

            @include breakpoint-min('large-tablet') {
                display: none;
            }
        }

        &--desktop {
            display: none;

            @include breakpoint-min('large-tablet') {
                display: block;
            }
        }
    }

    &__bg-asset {
        position: absolute;
        z-index: 10;
        display: none;

        @include breakpoint-min('large-tablet') {
            display: block;
        }

        &--circle {
            &-1 {
                top: 20px;
                left: 30px;
                transform: scale(0.5);

                @include breakpoint-min('small-screen') {
                    top: 100px;
                    left: 20px;
                    transform: scale(0.8);
                }

                @include breakpoint-min('desktop') {
                    top: 130px;
                    left: -80px;
                    transform: scale(1);
                }
            }

            &-2 {
                top: 40%;
                right: 30px;
                transform: scale(0.7);

                @include breakpoint-min('small-screen') {
                    top: 80px;
                    right: 20px;
                    transform: scale(0.8);
                }

                @include breakpoint-min('desktop') {
                    top: 35%;
                    right: -50px;
                }
            }

            &-3 {
                bottom: 20px;
                left: 50px;
                transform: scale(1);

                @include breakpoint-min('small-screen') {
                    bottom: 80px;
                    left: 120px;
                    transform: scale(0.6);
                }

                @include breakpoint-min('desktop') {
                    bottom: 140px;
                    left: 100px;
                }
            }
        }

        &--cross {
            &-1 {
                top: 0px;
                left: 80px;
                transform: scale(0.5);

                @include breakpoint-min('small-screen') {
                    top: 100px;
                    left: 35%;
                    transform: scale(0.6);
                }

                @include breakpoint-min('desktop') {
                    top: 160px;
                    left: 32%;
                }
            }

            &-2 {
                top: 60px;
                right: 20px;
                transform: scale(0.5);

                @include breakpoint-min('small-screen') {
                    top: auto;
                    bottom: 80px;
                    left: 40%;
                    transform: scale(1);
                }

                @include breakpoint-min('desktop') {
                    bottom: 80px;
                    left: 32%;
                }
            }

            &-3 {
                bottom: 0px;
                right: 100px;
                transform: scale(0.5);

                @include breakpoint-min('small-screen') {
                    bottom: 20%;
                    right: 20px;
                    transform: scale(0.6);
                }

                @include breakpoint-min('desktop') {
                    bottom: 25%;
                    right: 40px;
                }
            }

            &-4 {
                top: 50%;
                left: 40px;
                transform: scale(0.5);

                @include breakpoint-min('small-screen') {
                    top: 50%;
                    left: 0px;
                    transform: scale(0.6);
                }

                @include breakpoint-min('desktop') {
                    top: 50%;
                    left: -130px;
                }
            }
        }

        &--triangle {
            &-1 {
                top: 30px;
                right: 40px;
                transform: scale(0.8);

                @include breakpoint-min('small-screen') {
                    top: 30%;
                    left: 45%;
                    transform: scale(0.6);
                }

                @include breakpoint-min('desktop') {
                    top: 35%;
                    left: 40%;
                    transform: scale(0.8);
                }
            }

            &-2 {
                top: 40%;
                left: 30px;
                transform: scale(0.5);

                @include breakpoint-min('small-screen') {
                    top: auto;
                    bottom: 60px;
                    left: 35%;
                    transform: scale(0.6);
                }

                @include breakpoint-min('desktop') {
                    bottom: 55px;
                    left: 30%;
                    transform: scale(0.7);
                }
            }

            &-3 {
                bottom: 20px;
                right: 30px;
                transform: scale(0.8);

                @include breakpoint-min('small-screen') {
                    bottom: auto;
                    top: 40px;
                    right: 70px;
                    transform: scale(0.6);
                }

                @include breakpoint-min('desktop') {
                    top: 30%;
                    right: 0px;
                    transform: scale(0.7);
                }
            }
        }
    }

    &__section-title {
        &--no-margin {
            & .section-title {
                margin: 0;
            }
        }
    }
}