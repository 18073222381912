.ah-group {
    &-modal {
        &__blend {
            display: flex;
            justify-content: center;
            align-items: center;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            max-height: 100vh;
            opacity: 1;
            z-index: 50;
            background: transparentize(#F4EFE3, 0.1);
            transition: all $transitionDuration;
            animation: fadeIn $transitionDuration normal forwards ease-in-out;

            &--hide {
                display: none;
            }
        }

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: var($--theme-background);
        opacity: 1;
        width: 95%;
        height: 90vh;
        max-height: 90vh;
        box-sizing: border-box;
        height: auto;
        z-index: 51;
        transition: all $transitionDuration;
        animation: fadeIn $transitionDuration normal forwards ease-in-out;

        @include breakpoint-min('small-screen') {
            flex-direction: row-reverse;
            flex-wrap: nowrap;
            height: 75vh;
            max-height: 75vh;
        }

        @include breakpoint-min('desktop') {
            width: 85%;
        }

        @include rwdCustom(1800) {
            width: 75%;
        }

        &--hide {
            display: none;
        }

        &__banner { 
            &-warpper {
                width: 100%;
                min-height: 200px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                position: relative;

                @include breakpoint-min('tablet') {
                    min-height: 250px;
                }

                @include breakpoint-min('large-tablet') {
                    min-height: 300px;
                }

                @include breakpoint-min('small-screen') {
                    height: 100%;
                }
            }

            &-logo {
                position: absolute;
                left: 16px;
                top: 16px;
                background: var($--theme-background);
                padding: 15px;
            }
        }

        &__close-icon {
            position: absolute;
            top: 16px;
            right: 16px;
            cursor: pointer;
            background: var($--theme-background);
            padding: 5px;
        }

        &__content { 
            &-warpper {
                max-height: 75vh;
                overflow-y: auto;
                padding-top: 16px;
                padding-bottom: 16px;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                height: 100%;
            }
        }

        &__constants { 
            &-wrapper {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                margin-bottom: 30px;
                width: 100%;
            }

            &-row {
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: flex-start;
                margin-bottom: 10px;
            }

            &-title {
                font-size: 1rem; // 16px;
                line-height: 1.5rem; // 24px;
                text-transform: uppercase;
                text-align: left;
                margin-right: 20px;
                white-space: nowrap;
            }

            &-value {
                font-weight: $superBoldWeight;
                font-size: 1rem; // 16px;
                line-height: 1.5rem; // 24px;
                text-transform: uppercase;
                text-align: right;
            }
        }

        &__text {
            width: 100%;
            margin-bottom: 30px;
            white-space: pre-line;
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}