@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700;900&display=swap');
@import '../abstracts/variables';
@import '../abstracts/mixins';

html, body, * {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: $regularWeight;
    color: var($--theme-text);
    font-size: 1rem;
    line-height: 1.4rem;

    @include breakpoint-min('small-screen') {
        font-size: 1.1rem;
        line-height: 1.5rem;
    }
}

.text-color {
    &--primary {
        color: var($--theme-primary);
    }

    &--secondary {
        color: var($--theme-secondary);
    }
}

.no-wrap {
    white-space: nowrap;
}