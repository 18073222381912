.ah-group {
    &-tile {
        padding: 15px;
        border: 1px solid var($--theme-banner-background);
        transition: all $transitionDuration;
        cursor: pointer;
        margin-bottom: 15px;
        width: 100%;
        box-sizing: border-box;

        &--invisible {
            pointer-events: none;
            opacity: 0;
            height: 1px;
            padding: 0;
        }

        @include breakpoint-min('tablet') {
            width: 48%;
        }

        @include breakpoint-min('small-screen') {
            width: 23%;
        }

        &:hover {
            background: darken(#FFFFFF, 5%);
        }

        &__image {
            max-width: 100%;
            height: auto;
            min-height: 173px;
            
            &-wrapper {
                background-color: var($--theme-banner-background);
                width: 100%;
                height: auto;
                box-sizing: border-box;
                margin-bottom: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        &__title {
            text-transform: uppercase;
            font-size: 0.75rem; // 12px
            line-height: 1.2rem; // 20px
            font-weight: $regularWeight;
            text-align: left;

            &-wrapper {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                min-height: 43px;
            }
        }

        &__arrow-icon {
            cursor: pointer;
            transition: all $transitionDuration;

            &:hover {
                transform: scale(1.1);
            }
        }
    }
}