.historia {
    &__content-wrapper {
        margin-bottom: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;

        @include breakpoint-min('desktop') {
            margin-bottom: 120px;
        }
    }
}

.timeline {
    &__row-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        padding: 5px 0;
        border-bottom: 1px dashed var($--theme-primary-pale);
        
        &--prime {
            padding: 30px 0;
        }
    }

    &__year {
        min-width: 50px;
        width: 50px;
        font-weight: $lightWeight;
        font-size: 1.2rem; // 20px
        line-height: 1.75rem; // 28px
        color: var($--theme-primary-pale);
        text-align: right;
        display: block;
        width: auto;

        @include breakpoint-min('desktop') {
            font-size: 1.2rem; // 20px
            line-height: 2rem; // 32px
            width: 160px;
        }

        &--prime {
            color: var($--theme-primary);

            @include breakpoint-min('desktop') {
                font-size: 48px;
                line-height: 60px;
            }
        }
    }

    &__image {
        min-width: 60px;
        display: block;

        @include breakpoint-min('large-tablet') {
            margin: 0 30px;
        }
    
        @include breakpoint-min('desktop') {
            margin: 0 60px;
        }
    }

    &__text {
        width: 100%;
        font-weight: $regularWeight;
        font-size: 13px;
        line-height: 1.2rem; // 20px
        color: var($--theme-text);
        text-align: left;
        white-space: pre-line;

        span {
            font-weight: $boldWeight;
        }

        @include breakpoint-min('desktop') {
            font-size: 1rem; // 16px
        }
    }
}