.nav {
    background: var(--theme-background);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    width: 100%;

    &--open {
        box-shadow: none !important;
    }

    &--mobile {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 9px 16px;
        box-sizing: border-box;

        @include breakpoint-min('tablet') {
            padding: 16px 45px;
        }
    
        @include breakpoint-min('large-tablet') {
            padding: 16px 60px;
        }
    
        @include rwdCustom(1300) {
            padding: 16px 15px 16px 60px;
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 1920px;
        padding: 9px 16px;
        box-sizing: border-box;
        
        @include breakpoint-min('tablet') {
            padding: 16px 45px;
        }
    
        @include breakpoint-min('large-tablet') {
            padding: 16px 60px;
        }
    
        @include rwdCustom(1300) {
            padding: 16px 15px 16px 60px;
        }
    }

    &__logo {
        transition: $transitionDuration;
        width: 49px;
        height: 67px;

        @include breakpoint-min('large-tablet') {
            display: none;
        }

        &--big {
            display: none;
            width: 68px;
            height: 93px;

            @include breakpoint-min('large-tablet') {
                display: block;
            }
        }

        &:hover {
            transform: scale(1.05);
        }
    }

    &__menu {
        &-wrapper {
            display: none;

            @include rwdCustom(1300) {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-evenly;
                align-items: center;
            }
        }

        &-wrapper-mobile {
            display: none;
            z-index: 99;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            max-width: 100%;
            height: auto;
            background: var($--theme-background);
            color: var($--theme-text);
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            padding: 0 0 32px;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.45);

            @include breakpoint-min('tablet') {
                padding: 0 0 42px;
            }
        
            @include breakpoint-min('large-tablet') {
                padding: 0 0 52px;
            }

            &--show {
                display: flex;

                @include rwdCustom(1300) {
                    display: none;
                }
            }
        }

        &-item {
            color: var($--theme-text);
            position: relative;
            margin-bottom: 30px;
            text-decoration: none;
            text-align: center;
            font-size: 1rem;
            text-transform: uppercase;
            transition: all $transitionDuration;

            @include breakpoint-min('tablet') {
                // font-size: 1.2rem;
                margin-bottom: 45px;
            }

            @include breakpoint-min('large-tablet') {
                // font-size: 1.4rem;
                margin-bottom: 60px;
            }

            @include rwdCustom(1300) {
                margin-bottom: 0;
                font-size: 13px;
                margin-right: 45px;
            }

            &:hover {
                font-weight: $semiBoldWeight;

                @include rwdCustom(1300) {
                    font-weight: $regularWeight;
                    text-decoration: underline;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }

            &--active {
                border-bottom: 4px solid var($--theme-primary);
                padding: 0 0 8px 0;
                text-decoration: none;
                font-weight: $boldWeight;
                color: var($--theme-primary);

                &:hover {
                    font-weight: $boldWeight;
                    color: var($--theme-primary-pale);
                }

                @include rwdCustom(1300) {
                    border-bottom: none;
                    font-weight: $boldWeight;
                    padding: 0;

                    &::before {
                        content: '';
                        display: block;
                        width: 100%;
                        height: 6px;
                        background-color: var($--theme-primary);
                        position: absolute;
                        top: -49px;
                        left: 0;
                    }
                    
                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            &--separator {
                display: block;
                margin: 0 30px;
            }
        }

        &-icon {
            cursor: pointer;
            width: 24px;
            height: 24px;

            @include breakpoint-min('tablet') {
                width: 28px;
                height: 28px;
            }

            @include rwdCustom(1300) {
                display: none;
            }
        }
    }

    &__close-icon {
        width: 24px;
        height: 24px;
        cursor: pointer;
        transition: transform 500ms;

        &:hover {
            transform: rotate(180deg);
        }

        @include breakpoint-min('tablet') {
            width: 28px;
            height: 28px;
        }
    }
}