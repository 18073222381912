// --- colors

$--theme-primary: --theme-primary;
$--theme-primary-pale: --theme-primary-pale;
$--theme-secondary: --theme-secondary;
$--theme-text: --theme-text;
$--theme-background: --theme-background;
$--theme-banner-background: --theme-banner-background;
$--theme-red: --theme-red;
$--theme-green: --theme-green;

// Light theme (default)
$theme-map-light: (
    $--theme-primary: #CB9D29,
    $--theme-primary-pale: #E2D3AE,
    $--theme-secondary: #FFEB84,
    $--theme-text: #000000,
    $--theme-background: #FFFFFF,
    $--theme-banner-background: #F4EFE3,
    $--theme-red: #ff0000,
    $--theme-green: #00ff0d,
);

$neutral: #AFBAC5;

// --- typography

$lightWeight: 300;
$regularWeight: 400;
$semiBoldWeight: 600;
$boldWeight: 700;
$superBoldWeight: 800;

// --- breakpoints

$breakpoints: (
    'tablet':  ( min-width:  481px ),
    'large-tablet':  ( min-width:  769px ),
    'small-screen': ( min-width:  992px ),
    'desktop':  ( min-width: 1400px )
) !default;

// --- others

$maxWidth: 1400px;
$transitionDuration: 150ms;