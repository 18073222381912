.content {
    &-wrapper {
        display: block;
        width: 100%;
        height: auto;
        max-width: $maxWidth;
        margin: 0 auto;
        padding-top: 0;
        padding-bottom: 0;
        box-sizing: border-box;
        position: relative;
        padding-left: 16px;
        padding-right: 16px;

        @include breakpoint-min('large-tablet') {
            padding-left: 32px;
            padding-right: 32px;
        }
    }

    &__text {
        text-align: left;
        margin-bottom: 16px;
        font-size: 1rem; // 16px;
        line-height: 1.75rem; // 28px;
        color: var($--theme-text);
        width: 100%;

        @include breakpoint-min('small-screen') {
            width: 48%;
        }

        &--primary {
            color: var($--theme-primary);
        }

        &-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: 16px;

            @include breakpoint-min('small-screen') {
                flex-direction: row;
                justify-content: space-between;
            }
        }
    }
}