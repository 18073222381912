.theme {
    &-light {
        @include spread-map($theme-map-light);

        .text-hover {
            transition: all $transitionDuration;
        
            &:hover {
                color: lighten(map-get($theme-map-light, $--theme-text), 30%);
            }
        
            &--primary {
                &:hover {
                    color: darken(map-get($theme-map-light, $--theme-primary), 10%);
                }
            }
        
            &--secondary {
                &:hover {
                    color: darken(map-get($theme-map-light, $--theme-secondary), 10%);
                }
            }
        }

        .button {
            &:hover {
                background: lighten(map-get($theme-map-light, $--theme-text), 15%);
                border: 2px solid lighten(map-get($theme-map-light, $--theme-text), 15%);
                box-shadow: inset 0px 0px 2px 2px rgba(255, 255, 255, 0.15);
            }

            &--primary {
                &:hover {
                    background: darken(map-get($theme-map-light, $--theme-background), 10%);
                    border: 2px solid var($--theme-primary);
                    box-shadow: inset 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
                }
            }

            &--primary-filled {
                &:hover {
                    background: darken(map-get($theme-map-light, $--theme-primary), 6%);
                    border: 2px solid darken(map-get($theme-map-light, $--theme-primary), 6%);
                    box-shadow: inset 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
                }
            }
        }
    }
}