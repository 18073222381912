.footer {
    background: var($--theme-background);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    width: 100%;
    margin-top: auto;

    &__wrapper {
        width: 100%;
        max-width: 1920px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 16px;
        box-sizing: border-box;
        margin: 0 auto;
    
        @include breakpoint-min('small-screen') {
            padding: 16px 60px;
            justify-content: flex-start;
        }
    }

    &__text {
        text-transform: uppercase;
        font-size: 0.85rem;
        line-height: 1.2rem;

        @include breakpoint-min('small-screen') {
            margin-right: 60px;
        }

        &--bold {
            font-weight: $boldWeight;
        }
    }

    &__link {
        color: var($--theme-primary);
        display: block;
        text-transform: uppercase;
        font-size: 0.85rem;
        line-height: 1.2rem;

        &--inline {
            display: inline-block;
        }
    }
}