.content-footer {
    padding: 30px 30px 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: $maxWidth;
    margin: 0 auto;

    @include breakpoint-min('tablet') {
        padding: 45px 45px 60px;
    } 
    
    @include breakpoint-min('large-tablet') {
        padding: 60px;
    }

    @include breakpoint-min('small-screen') {
        padding: 90px 60px;
    }

    @include breakpoint-min('desktop') {
        padding: 90px 0;
    }

    &__image {
        margin-bottom: 30px;
    }

    &__title {
        text-align: center;
        padding: 0;
        margin-bottom: 15px;
    }

    &__text {
        text-align: center;
    }

    &__button {
        margin-top: 30px;
    }
}