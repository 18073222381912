.biura {
    &__group-wrapper {
        border: 1px solid var($--theme-banner-background);
        padding: 16px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 15px;
        width: 100%;
        box-sizing: border-box;

        &:first-child {
            margin-top: 16px;
        }

        &:last-child {
            margin-bottom: 60px;
        }

        @include breakpoint-min('large-tablet') {
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__company-logo {
        margin-bottom: 16px;
    }

    &__group-text-wrapper {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;

        @include breakpoint-min('large-tablet') {
            width: 85%;
        }
    }

    &__title {
        text-transform: uppercase;
        text-align: left;
        font-size: 1.5rem; // 24px
        line-height: 2rem; // 32px
        color: var($--theme-primary);
        font-weight: $lightWeight;
        margin-bottom: 5px;
        white-space: pre-line;
    }

    &__text {
        text-align: left;
        font-weight: $lightWeight;
        font-size: 1rem; // 16px
        line-height: 1.75rem; // 28px
        margin-bottom: 5px;

        &--additional {
            font-weight: $regularWeight;
            text-align: left;
        }
    }

    &__button {
        width: 100%;
        
        .custom-button {
            width: 100%;
            justify-content: space-between;
            padding: 20px 18px;
            margin-top: 30px;

            @include breakpoint-min('large-tablet') {
                width: auto;
            }

            &__icon {
                margin-left: 30px;
            }
        }
    }
}