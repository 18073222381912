.App {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: var($--theme-background);
    text-align: center;
}

.ah-group {
    &__tiles-wrapper {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        width: 100%;

        @include breakpoint-min('tablet') {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;
        }
    }
}