.artur-hawryluk {
    &__section-wrapper {
        @include rwdCustom(960) {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: flex-start;
        }

        &--reverse {
            flex-direction: row-reverse;
        }
    }

    &__image {
        display: none;
        max-width: 50%;
        width: auto;
        height: auto;
        
        @include rwdCustom(960) {
            display: block;
        }

        &--mobile {
            display: block;
            max-width: 100%;
            height: auto;

            @include rwdCustom(960) {
                display: none;
            }
        }
    }

    &__text {
        padding: 32px 16px;
        font-weight: $lightWeight;
        font-size: 1rem; // 16px
        line-height: 1.75rem; // 28px
        color: var($--theme-text);
        text-align: left;

        @include rwdCustom(960) {
            padding: 30px;
        }

        @include rwdCustom(1100) {
            padding: 50px;
        }

        @include breakpoint-min('desktop') {
            padding: 90px;
            font-size: 1.2rem; // 20px
            line-height: 2rem; // 32px
        }

        @include rwdCustom(1600) {
            padding: 120px;
        }

        @include rwdCustom(1800) {
            padding: 175px;
        }

        @include rwdCustom(1900) {
            padding: 200px;
        }

        &--bold {
            font-weight: $boldWeight;
        }

        &--no-padding-vertical {
            padding-top: 32px;
            padding-bottom: 32px;

            @include breakpoint-min('desktop') {
                padding-top: 64px;
                padding-bottom: 64px;
            }
        }
    }
}