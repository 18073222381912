.custom-button {
    &__link-wrapper {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    color: var($--theme-primary);
    border: 2px solid var($--theme-primary);
    background: var($--theme-background);
    text-transform: uppercase;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    padding: 6px;
    font-size: 0.70rem;
    line-height: 1.1rem;
    display: flex;
    text-decoration: none;
    cursor: pointer;
    transition: all $transitionDuration;

    &:hover {
        background: darken(#FFFFFF, 10%);
    }

    @include breakpoint-min('small-screen') {
        padding: 18px;
    }

    @include breakpoint-min('desktop') {
        font-size: 0.85rem;
        line-height: 1.2rem;
    }

    &__icon {
        margin-left: 10px;

        @include breakpoint-min('small-screen') {
            margin-left: 30px;
        }
    }
}